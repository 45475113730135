import React, {createContext, Dispatch, lazy, Suspense, useCallback, useContext, useEffect, useState} from "react";
import {iStoreAction} from "../reducer";
import {AppDispatchContext, AppStateContext} from "../App";
import useIsMounted from "ismounted";
import BaseAPIs from "../apis/base.apis";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Alert, AlertColor, Snackbar} from "@mui/material";
import {CustomLoader} from "../Spinner/CustomLoader";
import {QueryParser1} from "../components/QueryParser";

const Login = lazy(() => import('./Login/Login/Login'));
const AdminRoutes = lazy(() => import('./Admin/AdminRoutes'));

export const SnackbarContext = createContext<{
    showSuccessSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void,
    showErrorSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void
}>({
    showErrorSnackbarMessage: args => {
    },
    showSuccessSnackbarMessage: args => {
    },
});
export default function AppRouter() {

    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);

    const [loading, setLoading] = useState(true);

    const isMounted = useIsMounted();
    const onFetchUser = useCallback(() => {
        setLoading(true);
        new BaseAPIs().get("/_api/v2/admin/get-user")
            .then((response) => {
                if (isMounted.current) {
                    if (BaseAPIs.hasError(response)) {
                        setLoading(false);
                    } else {
                        dispatch({
                            type: "set_logged_in_user",
                            loggedInUser: response.admin_user,

                        })
                        setLoading(false);

                    }
                }
            });
    }, [isMounted]);

    useEffect(() => {
        onFetchUser();
    }, [])

    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === 'loggedInUser_Directship' && e.oldValue && !e.newValue) {
                window.location.reload();
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    const [snackBarState, setSnackBarState] = useState<{
        show: boolean,
        severity: AlertColor,
        message: string
    }>({
        show: false,
        severity: "success",
        message: "Hi"
    });
    if (loading) {
        return <CustomLoader/>;
    }
    return <>
        <SnackbarContext.Provider value={{
            showErrorSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "warning", ...args}
                });
            }),
            showSuccessSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "success", ...args}
                });
            }),
        }}>
            <Suspense fallback={<CustomLoader/>}>
                <Routes>
                    <Route path="/admin/*"
                           element={<RequireAuth  redirect={"/login"}><AdminRoutes/></RequireAuth>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<RequireAuth  redirect={"/login"}><AdminRoutes/></RequireAuth>}/>

                </Routes>
            </Suspense>
        </SnackbarContext.Provider>
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={snackBarState.show} autoHideDuration={6000} onClose={() => {
            setSnackBarState(prevState => {
                return {...prevState, show: false}
            });
        }}>
            <Alert onClose={() => {
                setSnackBarState(prevState => {
                    return {...prevState, show: false}
                });
            }} severity={snackBarState.severity} sx={{width: '100%'}}>
                {snackBarState.message}
            </Alert>
        </Snackbar>
    </>
}
export const RequireAuth = ({

                                noAuth,
                                redirect,
                                children
                            }: {
    noAuth?: boolean,
    redirect?: string,
    children: any,

}) => {
    const {loggedInUser} = useContext(AppStateContext)

    const location = useLocation()
    if (noAuth) {
        if (!loggedInUser) {
            return children;
        }
    }
    if (loggedInUser) {
        return children;
    }

    let query = QueryParser1(location)
    if (query && query.go) {
        return <Navigate replace to={"/" + query.go}/>
    }
    return <Navigate replace to={redirect || "/"}/>
}
