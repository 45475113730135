import axios, {CancelTokenSource} from 'axios';
import {iApiBasicResponse, iCsrfToken, iCsrfTokenResponse} from "../types/types";
import {AlertColor} from "@mui/material";
import fileDownload from 'js-file-download'

export default class BaseAPIs {
    static csrfToken: iCsrfToken = {
        token: "dummy_expired_token",
        expiry: new Date(0)
    };
    static hasError = (response: iApiBasicResponse, showSuccessSnackbarMessage?: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void) => {

        if (!response || response.statusCode >= 400) {
            response &&
            response.message &&
            showSuccessSnackbarMessage &&
            showSuccessSnackbarMessage({message: response.message, severity: "error", show: true})
        } else {
            response &&
            response.message &&
            showSuccessSnackbarMessage &&
            showSuccessSnackbarMessage({message: response.message, severity: "success", show: true})
        }
        if (!response || response.statusCode >= 400) return true;
        if (!response || response.validation_errors) return true;
    };
    static getError = (response: iApiBasicResponse): string => {
        if (response && response.message && response.message === "string") return response.message;
        if (response && !response.validation_errors) return BaseAPIs.getErrorFromStatusCode(response);
        return "Unknown error!";
    };
    static getErrorFromStatusCode = (response: iApiBasicResponse): string => {
        switch (response.statusCode) {
            case 400:
                return "Request error: Bad Request";
            case 401:
                return "Request error: Unauthorized";
            case 402:
                return "Request error: Payment Required";
            case 403:
                return "Request error: Forbidden";
            case 404:
                return "Request error: Not Found";
            case 405:
                return "Request error: Method Not Allowed";
            case 500:
                return "Server error: Internal error";
            case 501:
                return "Server error: Not Implemented";
            case 502:
                return "Server error: Bad Gateway";
            case 503:
                return "Server error: Service Unavailable";
            case 504:
                return "Server error: Gateway Timeout";
            default:
                return "Unknown error";
        }
    };

    private static getErrorString(error: string | Array<string>) {
        if (typeof error === "string") return error;
        else return error.join(" ");
    }

    post = async (url: string, fd?: any): Promise<any> => {
        let data: any = {};
        if(fd){
            data = {...fd}
        }
        if (!data["_token"]) {
            let csrfToken: string = await new BaseAPIs().getCsrfToken(true);
            if (!csrfToken) return {statusCode: 999, message: "Network error!"};

            if (fd) {
                data["_token"] = csrfToken;
            } else {
                data["_token"] = csrfToken;
            }
        }

        return axios
            .post(this.getApiBaseURL() + url, data, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: BaseAPIs.getError(error.response), ...error.response.data,
                        statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: BaseAPIs.getError(error.response)};
                }
            })
    };
    post_with_multipart = async (url: string, fd: any, onUploadProgress?: any): Promise<any> => {
        let csrfToken: string = await new BaseAPIs().getCsrfToken();
        if (!csrfToken) return {statusCode: 999, message: "Network error!"};
        fd.set("_token", csrfToken);

        return axios
            .post(this.getApiBaseURL() + url, fd, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: true,
                onUploadProgress: onUploadProgress
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: "Network Error", ...error.response.data, statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: "Network Error"};
                }
            })
    };
    get = async (url: string, params?: any, abortController?: AbortController): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                signal: abortController && abortController.signal
            })
            .then((res): any => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                if (error && error.response && error.response.status && error.response.data) {
                    return {
                        message: BaseAPIs.getError(error.response), ...error.response.data,
                        statusCode: error.response.status
                    }
                }
                if (error && error.response && error.response.status) {
                    if (error.response.status === 404) {
                        return {
                            message: BaseAPIs.getError(error.response), statusCode: error.response.status
                        }
                    }
                    return {
                        message: BaseAPIs.getError(error.response), statusCode: error.response.status
                    }
                } else {
                    return {statusCode: 999, message: BaseAPIs.getError(error.response)};
                }
            })
    };
    download = async (url: string, download_name: string, params?: any, onDownloadProgress?: any): Promise<any> => {
        return axios
            .get(this.getApiBaseURL() + url, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                params: params,
                cancelToken: params && params.cancel_token_source && params.cancel_token_source.token,
                responseType: "blob",
                onDownloadProgress: onDownloadProgress
            })
            .then((res: any): any => {
                fileDownload(res.data, download_name);
            })
            .catch((): any => {

            })
    };
    getApiBaseURL = () => {
        return process.env.REACT_APP_SERVER_URL;
    };
    // getGetUrl = (url:string,params:any) => {
    //
    //     Object.keys(params).forEach((key) => {
    //         if (params[key] === undefined || params[key] === '') {
    //             delete params[key];
    //         }
    //     });
    //     const u = new URLSearchParams(params).toString();
    //
    //
    //     return this.getApiBaseURL()+url+"?"+u;
    // };


    getCsrfToken = async (refresh_token: boolean = false, source?: CancelTokenSource): Promise<string> => {
        return this.fetchCsrfToken(source).then((r: any) => {
            if (BaseAPIs.hasError(r) || !r.token) {
                return "";
            } else {
                return r.token;
            }
        })
    };

    fetchCsrfToken = (source?: CancelTokenSource): Promise<iCsrfTokenResponse> => {
        return axios
            .get(this.getApiBaseURL() + "/_api/v2/csrf-token", {
                withCredentials: true,
                cancelToken: source && source.token
            },)
            .then((res): iCsrfTokenResponse => ({statusCode: res.status, ...res.data}))
            .catch((error): any => {
                console.log(error)
            });
    };
}
