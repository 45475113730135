import {iStoreState, iUser} from "./types/types";


export const getInitialStoreState = (): iStoreState => {
    return {
        loggedInUser: undefined,
        statusCode: 200,

    }
};
export type iStoreAction =
    | { type: 'verifying_user' }
    | { type: 'failed_to_user' }
    | { type: 'set_logged_in_user', loggedInUser: iUser | undefined}
    | { type: 'logout', }


export function appReducer(state: iStoreState, action: iStoreAction): iStoreState {
    switch (action.type) {
        case 'set_logged_in_user':
            window.localStorage.setItem('loggedInUser_Directship', (action.loggedInUser && action.loggedInUser?.email) || "");
            return {...state, loggedInUser: action.loggedInUser};

        case 'logout':
            window.localStorage.removeItem('loggedInUser_Directship');
            return {...state, loggedInUser: undefined};

        default:
            return {...state};
    }
}


